<template>
  <div>
    <div class="foot">
      <div class="foot_top clearFix">
        <div class="fl foot_top_left">
          <ul>
            <li>新闻动态</li>
            <li>合作伙伴</li>
            <li>核心业务及产品</li>
            <li>关于药联</li>
            <li>核心能力</li>
            <li>平台资质</li>
          </ul>
          <div><img src="../assets/image/foot-tel.png" alt="">电&#12288;&#12288;话：021-55099707</div>
          <div><img src="../assets/image/foot-local.png" alt="">地&#12288;&#12288;址：上海市静安区江场路1401弄市北壹中心9号楼</div>
          <div><img src="../assets/image/foot_email.png" alt="">商户合作：shangwu@uniondrug.com</div>
        </div>
        <div class="fr foot-right">
          <div class="foot_top_erm fl">
            <img src="../assets/image/erm_uniondrug.png" alt="">
            <span>【关注药联公众号】</span>
          </div>
          <!-- <div class="foot_top_erm fl">
            <img src="../assets/image/ewm_yl.png" alt="">
            <span>【下载药联健康APP】</span>
          </div> -->
          <div class="foot_top_erm fl">
            <img src="../assets/image/ewm_app.png" alt="">
            <span>【下载药店宝APP】</span>
          </div>
        </div>
      </div>
      <div class="foot_bottom">
        <div class>互联网药品信息服务资格证书：(沪)-经营性-2019-0068</div>
        <div>增值电信业务经营许可证：沪B2-20190886</div>
        <div>医疗器械网络交易服务第三方平台备案凭证:（沪）网械平台备字[2020]第 00005 号</div>
        <div>
          上海聚音信息科技有限公司 版权所有 <a  href="http://beian.miit.gov.cn/" target="_blank">沪ICP备13028978号-3</a>
        </div>
        <!-- 安全证书 -->
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1534378868599656448&language=CN&certType=8&url=*.uniondrug.cn" target="_blank"
          class="xcc-box">
          <embed src="https://program.xinchacha.com/web/1534378868599656448=*.uniondrug.cn.svg" width="103" height="38"
            type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'foot-box',
  data() {
    return {
      page: this.$route.path
    }
  },
  created() {},
  watch: {
    $route(to, from) {
      this.page = to.path
    }
  },
  methods: {
    to(e) {
      window.scrollTo(0, 0)
      if (e != this.$route.path) {
        this.$router.push({
          path: e
        })
      }
    },
    jump(){
      let zizhi
      if (location.host.indexOf('uniondrug.cn') > -1) {
        zizhi = 'https://wx.uniondrug.cn/v/intelligent'
      } else if (location.host.indexOf('uniondrug.net') > -1) {
        zizhi = 'https://wx.uniondrug.net/v/intelligent'
      } else if (location.host.indexOf('turboradio.cn') > -1) {
        zizhi = 'https://wx.turboradio.cn/v/intelligent'
      } else if (location.host.indexOf('localhost') > -1) {
        zizhi = 'https://wx.turboradio.cn/v/intelligent'
      } else {
        zizhi = 'https://wx.turboradio.cn/v/intelligent'
      }
      window.location.href = zizhi
    }
  }
}
</script>

<style lang="less" scoped>
.foot {
  width: 100%;
  min-width: 1200px;
  background: #474747;
  overflow: hidden;
  .foot_top {
    width: 1200px;
    margin: auto;
    padding: 29px 0;
    .foot_top_left {
      color: #9c9c9c;
      font-size: 14px;
      padding-top: 10px;
      ul {
        width: 100%;
        overflow: hidden;
        height: auto;
        padding: 4px 0 25px;

        li {
          width: 50%;
          float: left;
          color: #9c9c9c;
          padding-bottom: 16px;
          cursor: pointer;
        }
        li:hover {
          color: #fff;
        }
      }
      div {
        font-size: 14px;
        padding: 0 0 17px;
        img {
          width: 15px;
          padding-right: 20px;
          vertical-align: middle;
        }
      }
    }

    .foot-right {
      .foot_top_erm {
        margin-left: 73px;
        img {
          width: 166px;
          height: 166px;
          display: block;
        }
        span {
          color: #9c9c9c;
          font-size: 12px;
          text-align: center;
          display: block;
          padding-top: 17px;
        }
      }
    }
  }
  .foot_bottom {
    width: 1400px;
    margin: auto;
    color: #868686;
    font-size: 12px;
    border-top: 1px solid rgba(130, 130, 130, 0.5);
    text-align: center;
    box-sizing: border-box;
    padding: 18px 0 15px;

    >div {
      padding: 0 0 8px;
    }
        .xcc-box {
          width: 103px;
          height: 38px;
        }
  }
  .foot_bottom a{
    display: inline;
    cursor: pointer;
    text-decoration: underline;
  }
  .foot_bottom a:hover{
    color: #06cfb5;
  }
}
</style>
