import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// title:标题
// isKeepAlive: 记住路由返回上一页不会刷新数据
// requireAuth: 路由是否需要登录权限

export default new Router({
    mode: 'history',
    routes: [{
        path: '',
        name: 'index',
        component: () => import('../views/home.vue'),
        meta: {
            title: '药联健康_药联健康官网',
            isKeepAlive: false,
        },
    }, {
        path: '/news',
        name: 'news',
        component: () => import('../views/news.vue'),
        meta: {
            title: '新闻动态_药联健康',
            isKeepAlive: false,
        },
    }, {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/news_detail.vue'),
        meta: {
            title: '新闻动态_药联健康',
            isKeepAlive: false,
        },
    }, {
        path: '/business',
        name: 'business',
        component: () => import('../views/business.vue'),
        meta: {
            title: '核心业务及产品_药联健康',
            isKeepAlive: false,
        },
    }, {
        path: '/coreability',
        name: 'coreability',
        component: () => import('../views/coreability.vue'),
        meta: {
            title: '核心能力_药联健康',
            isKeepAlive: false,
        },
    }, {
        path: '/partner',
        name: 'page3',
        component: () => import('../views/page3.vue'),
        meta: {
            title: '合作伙伴_药联健康',
            isKeepAlive: false,
        },
    }, {
        path: '/about',
        name: 'page4',
        component: () => import('../views/page4.vue'),
        meta: {
            title: '关于药联_药联健康',
            isKeepAlive: false,
        },
    }, {
        path: '/empty',
        name: 'empty',
        component: () => import('../views/empty.vue'),
        meta: {
            title: '药联健康',
            isKeepAlive: false,
        },
    }, {
        path: '**',
        name: '404',
        component: () => import('../views/home.vue'),
        meta: {
            title: '药联健康',
            isKeepAlive: false,
        },
    }, ],
});
