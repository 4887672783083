<template>
  <div>
    <div class="hiddenText">药联健康成立于2014年，总部位于上海，在北京、南京分别设有业务中心和研发中心。截至2019年4月，拥有员工230余人，其中技术研发人员超过120人。</div>
    <div class="header_out coverWidth">
      <div class="header clearFix">
        <div class="header-left fl" @click="to('/')">
          <img src="../assets/image/logo.png" alt="">
        </div>
        <div class="fl">
          <ul class="clearFix">
            <li class="link-item" @click="to('/')" :class="page == '/' ? 'on':''">首页</li>
            <li :class="page == '/news' || page == '/detail' ? 'on':''">新闻动态</li>
            <li :class="page == '/business' ? 'on':''">核心业务及产品</li>
            <li :class="page == '/coreability' ? 'on':''">核心能力</li>
            <li :class="page == '/partner' ? 'on':''">合作伙伴</li>
            <li :class="page == '/about' ? 'on':''">关于药联</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'header-box',
  data() {
    return {
      page: this.$route.path
    }
  },
  created() {},
  watch: {
    $route(to, from) {
      this.page = to.path
    }
  },
  methods: {
    to(e) {
      window.scrollTo(0, 0)
      if (e != this.$route.path) {
        this.$router.push({
          path: e
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.hiddenText {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.header_out {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  z-index: 500;
}
.header {
  width: 1200px;
  height: 70px;
  margin: auto;
}

.header {
  &-left {
    width: 234px;
    margin: 17px 108px 0 0;
    cursor: pointer;
  }
}

.header .fl img {
  width: 100%;
  height: 100%;
}

.header ul {
  height: 100%;
  li {
    float: left;
    color: #777777;
    padding: 0 24px;
    font-size: 18px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    margin: 0 24px 0 0;
    &.link-item {
      cursor: pointer;
    }
    &:last-child {
      margin: 0;
    }
    &.link-item:hover{
      background: transparent;
      color: #00B095;
    }
    &.link-item.on:hover {
      background: #00B095;
      color: #ffffff;
    }
  }
  li.on {
    background: #00B095;
    color: #fff;
    font-size: 18px;
  }
  li:last-child {
    margin-right: 0;
  }
}
</style>

