<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './components/header'
import Footer from './components/footer'
export default {
  name: 'app',
  data() {
    return {
      HeaderShow: false,
      FooterShow: false,
      routerShow: true
    }
  },
  components: {
    Header: Header,
    Footer: Footer
  }
}
</script>

<style lang="scss">
</style>