import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/app.css';
import 'vant/lib/index.css';
import '@/assets/css/app.css';
import api from './core/net/api';
import VueCookies from 'vue-cookies';

Vue.use(ElementUI)
Vue.config.productionTip = false;
// 全局变量定义
Vue.prototype.$cookies = VueCookies;
Vue.prototype.$get = api.get;
Vue.prototype.$post = api.post;


// 授权
router.beforeEach((to, from, next) => {
    let url = '';
    if (location.host.indexOf('uniondrug.cn') > -1) {
        url = 'http://mobile.uniondrug.cn/unionhealth';
    } else if (location.host.indexOf('uniondrug.net') > -1) {
        url = 'http://mobile.uniondrug.net/unionhealth';
    } else if (location.host.indexOf('turboradio.cn') > -1) {
        url = 'http://mobile.turboradio.cn/unionhealth';
    } else if (location.host.indexOf('localhost') > -1) {
        url = 'http://mobile.turboradio.cn/unionhealth';
    } else {
        url = 'http://mobile.turboradio.cn/unionhealth';
    }
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)){
        window.location.href = url
    }else{
        // 配置标题
        document.title = to.meta.title ? to.meta.title : '药联健康';
        if(to.name==='news' || to.name === 'detail'){
            Vue.prototype
            .$post('/news/list', {
              page: 1,
              limit: 5
            })
            .then(res => {
              sessionStorage.setItem('list', JSON.stringify(res.data))
              next();
            })
        }else{
            next()
        }
        
    }
});

new Vue({
    router,
    // store: stores,
    render: h => h(App),
    mounted () {
        document.dispatchEvent(new Event('uniondrug-render-event'))
    }
}).$mount('#app');